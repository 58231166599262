import { Typography, styled } from "@mui/material"
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard"

export const PersonName = styled(Typography)`
  color: #8d8d8d;
  font-weight: ${({ theme }) => theme?.typography?.fontWeightLight || 300};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`
export const AllocatedDate = styled(Typography)`
  font-size: 14px;
  font-weight: ${({ theme }) => theme?.typography?.fontWeightMedium || 500};
`
export const BookingId = styled(Typography)`
  font-size: 10px;
  font-style: italic;
  color: #8d8d8d;
`

export const GiftDayPassIcon = styled(CardGiftcardIcon)`
  font-size: 12px;
  color: #8d8d8d;
`
